'use strict';

angular.module('enervexSalesappApp').controller('MainCtrl', function($scope, $http, Auth) {
	// $scope.awesomeThings = [];

	// $http.get('/api/things').success(function(awesomeThings) {
	// 	$scope.awesomeThings = awesomeThings;
	// });

	// $scope.addThing = function() {
	// 	if ($scope.newThing === '') {
	// 		return;
	// 	}
	// 	$http.post('/api/things', {
	// 		name: $scope.newThing
	// 	});
	// 	$scope.newThing = '';
	// };

	// $scope.deleteThing = function(thing) {
	// 	$http.delete('/api/things/' + thing._id);
	// };
	Auth.getSession().then(function(result){
		//goto accounts screen
		if (result.user){
			window.location.href="/accounts";
		} else {
			window.location.href="/auth/login"
		}
		console.log("user is "+result.user)
		console.log("token is "+result.token)
	}).catch(function(err){
		window.location.href="/auth/login"
		console.log("no dice on the getSession")
	})

});
